import { generateRandomNumber, generateRandomString, getNow } from '@utils/common';
import constant from '@utils/constant';
import useEventInfo, {
   EventInfoContext,
} from '@views_admin/event-info/hooks/useEventInfo';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import IconArrow16Bold from '@icons/IconArrow16Bold';
import { Modal } from 'antd';
import EventInfo from '@views_admin/event-info/EventInfo';
import SEO from '@components/SEO';

const getInitialValues = () => {
   return {
      code: `${generateRandomString(3).toUpperCase()}${generateRandomNumber(8)}`,
      start_date: getNow(),
   };
};

const EventDetails = memo(() => {
   const {
      hasDataChanged,
      initialvalues,
      setInitialData,
      submitting,
      onSubmit,
      ...eventStates
   } = useEventInfo();
   const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

   useEffect(() => {
      setInitialData(getInitialValues());
   }, [setInitialData]);

   const goBack = () => {
      if (!hasDataChanged) {
         navigate(constant.ROUTE_ADMIN_EVENTS);
      } else {
         setIsConfirmationOpen(true);
      }
   };

   const cancelLeavePage = useCallback(() => {
      setIsConfirmationOpen(false);
   }, []);

   const confirmLeavePage = useCallback(() => {
      setIsConfirmationOpen(false);
      navigate(constant.ROUTE_ADMIN_EVENTS);
   }, []);

   return (
      <>
         <SEO title={'Add Event - Admin'} />
         <EventInfoContext.Provider
            value={{
               ...eventStates,
            }}
         >
            <div className="admin-product-details">
               <div className="content-head admin-product-details__header">
                  <div className="content-head__left">
                     <label className="title">
                        <i onClick={goBack}>
                           <IconArrow16Bold />
                        </i>
                        Add Event
                     </label>
                  </div>
                  <div className="content-head__right admin-actions-group">
                     <button className="cancel" onClick={goBack}>
                        Cancel
                     </button>
                     <button className="submit" onClick={onSubmit} disabled={submitting}>
                        Save
                     </button>
                  </div>
               </div>
               <div className="content-body">
                  <EventInfo />
               </div>
            </div>
         </EventInfoContext.Provider>
         <Modal
            className="modal-confirm-leave-page"
            wrapClassName="modal-confirm-leave-page-container"
            title={null}
            visible={isConfirmationOpen}
            closable={false}
            maskClosable={true}
            onOk={confirmLeavePage}
            onCancel={cancelLeavePage}
            destroyOnClose={true}
            width={320}
            zIndex={4039}
            footer={
               <div className="modal-confirm-leave-page__footer">
                  <button className="cancel" onClick={cancelLeavePage}>
                     Stay
                  </button>
                  <button className="confirm" onClick={confirmLeavePage}>
                     Leave
                  </button>
               </div>
            }
         >
            <div className="modal-confirm-leave-page__content">
               <label>Confirm to leave page</label>
               <p>Changes you made may not be saved.</p>
            </div>
         </Modal>
      </>
   );
});

export default EventDetails;
